<template>

    <div id="methodology-section" class="section methodology">
      <div class="content">
      <h3 class="section-title">Metodología</h3>
      <ul class="methods-list">
        <li class="methods-list__item"> <span class="list-number">01</span> Escuchamos tus necesidades</li>
        <li class="methods-list__item"> <span class="list-number">02</span> Hacemos un plan estratégico a tu medida</li>
        <li class="methods-list__item"> <span class="list-number">03</span> Echamos a volar nuestra creatividad</li>
        <li class="methods-list__item"> <span class="list-number">04</span> Producimos y ejecutamos</li>
        <li class="methods-list__item"> <span class="list-number">05</span> Medimos y direccionamos</li>
      </ul>
      </div>
    </div>

</template>

<style scoped>

.methodology {
  padding: 40px;
}

.section-title {
  color: var(--white);
  letter-spacing: 5px;
  text-transform: uppercase;
  width: min-content;
  margin-right: 40px;
  word-wrap: break-word;
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1200px;
  margin: 0 auto;
  height: 100%;
}


.methods-list {
  display:flex;
  flex-direction: column;
  justify-content: center;
  list-style-type: none;
  max-width: 1000px;
  margin: 30px 0;
  padding: 0;
  border-left: 1px solid var(--white);
  height: 100%;
}

.methods-list__item {
  list-style-position: outside;
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  font-weight: 600;
  padding: 0px 10px;
  margin: 30px 0;
  text-align: left;
  border-left: 5px solid var(--brightblue);
}

.list-number {
    display: grid;
    place-items: center;
    margin-right: 15px;
    padding: 12px;
    border-radius: 50%;
}

@media (max-height: 900px) {
   .methodology {
    padding: 200px 20px;
  }

  .methods-list__item {
  margin: 20px 0;
}

}

@media (max-width:850px) {

  .content {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .section-title {
    font-size: 2.2rem;
    margin-right: 0;
  }

  .methods-list {
 margin-top: 0px;
}
}

</style>