<template>
  <div class="tab-wrapper">
    <div class="text-section">
      <h4 class="section-title">Colaboradores informados y felices</h4>
      <p>
        Las campañas empresariales son primordiales para transmitir la esencia
        de tu empresa. Ayudan a que los colaboradores participen y conozcan
        sobre los sucesos más importantes, valores, misiones o los retos
        próximos al desarrollo de la empresa. Todo esto se puede lograr sin
        sentir que la participación se convierte en trabajo extra.
      </p>
      <p>Ahí es donde entramos nosotros.</p>
      <p>
        Planteamos estrategias, seleccionamos canales, creamos contenido y
        ejecutamos. Hacemos que tus mensajes lleguen a su audiencia meta de una
        manera creativa y medible.
      </p>
      <div class="buttons-container">
        <contact-button></contact-button>
        <initial-button></initial-button>
      </div>
    </div>
    <div class="image-container">
      <img
        class="logos-image"
        src="../../../assets/Images/PreviousProyects/campaigns.png"
        alt="office-campaign-example"
      />
    </div>
  </div>
</template>

<script>
import ContactButton from "../../GeneralUI/ContactButton.vue";
import InitialButton from "../../GeneralUI/InitialButton.vue";

export default {
  components: {
    ContactButton,
    InitialButton,
  },
};
</script>


<style scoped>
.tab-wrapper {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
  position: relative;
}

.text-section {
  padding: 20px;
}

.section-title {
  margin: 20px 0;
}

.logos-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.buttons-container {
  position: absolute;
  left: 20px;
  bottom: 30px;
  width: 100%;
  margin-top: 20px;
  display: flex;
  column-gap: 30px;
}

@media (max-width: 850px) {
  .section-title {
    font-size: 2rem;
    margin-right: 0;
  }

  .tab-wrapper {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 100%;
    place-items: center;
    overflow: hidden;
    position: relative;
  }

  .logos-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.35;
  }

  .image-container {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-color: black;
  }

  .buttons-container {
    position: relative;
    flex-direction: column;
    left: 0;
    bottom: 0;
    row-gap: 40px;
    margin: 30px auto;
    height: 100%;
  }

  .text-section {
    z-index: 100;
    overflow-y: auto;
  }
}
</style>