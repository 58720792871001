<template>
  <div class="section design-section">

    <div class="window-frame">
    <router-view v-slot="{Component}" class="router-view">
    <transition name="slide" mode="out-in">
      <component :is="Component" :key="$route.path"></component>
    </transition>
    </router-view>
    </div>

    <h2 class="continue-message">continúa explorando <span class="material-icons">keyboard_arrow_down</span></h2>

  </div>
</template>

<style scoped>
.design-section {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 40px;
}

.section-title {
  color: var(--white);
  margin-top: 0;
  text-align: center;
}

.window-frame {
  border: 1px solid var(--white);
  max-height: 600px;
  height: 500px;
  min-height: 400px;
  max-width: 1200px;
  width: 100%;
  min-width: 800px;
  margin: 20px 0;
  overflow: hidden;
}

.router-view {
  height: 100%;
  width: 100%;
}

.hint {
  font-weight: 300;
  font-size: 1rem;
}

.router-link {
  text-decoration: none;
}


.slide-enter-active,
.slide-leave-active {
  transition: transform 1s;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateY(-100%);
}

/* .slide-leave-from,
.slide-leave-to {
  transform: translateY(-100%);
} */

.continue-message {
  position: absolute;
  bottom: 10px;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.continue-message span {
  margin-left: 5px;
}

@media (max-height: 750px) {
  .design-section {
    padding: 0;
    height: var(--screen-size);
  }

   .window-frame {
    width: 100vw;
    height: var(--screen-size);
    max-height: unset;
    min-width: unset;
    border: none;
  }

  .continue-message {
    display: none;
  }
}

@media (max-width: 850px) {
  .design-section {
    padding: 0;
    height: var(--screen-size);
  }

  .section-title {
    font-size: 2.2rem;
    margin-right: 0;
  }

  .continue-message {
    display: none;
  }

  .description-row {
    margin: 10px 0;
  }

  .window-frame {
    margin: 0;
    width: 100vw;
    height: var(--screen-size);
    max-height: unset;
    min-width: unset;
    border: none;
  }

}
</style>