<template>
  <div class="spinner-container">
    <h3 class="spinner-phrase">We</h3>
    <div class="slider-container">
      <h3 class="slider-text1">are your creative lighthouse </h3>
      <h3 class="slider-text2">create and print awesome stuff </h3>
      <h3 class="slider-text3">We are your creative lighthouse</h3>
      <h3 class="slider-text4">We create and print awesome stuff</h3>
    </div>
  </div>
</template>

<style scoped>

.spinner-container {
  --containerh: 70px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-top: 30px;
}

.spinner-phrase {
  color: var(--accent1);
  margin: 0px auto;
  padding: 0;
}

.slider-container {
    height: var(--containerh);
    padding-left: 15px;
    overflow: hidden;
}

.slider-container h3 {
    margin-bottom: var(--containerh);
    text-align: left;
    height: var(--containerh);
    padding: 0;
    color: var(--darkbackground);
}

.slider-text1, .slider-text3 {
    animation: slide 5s linear infinite;
}

.slider-text3,.slider-text4 {
  display: none;
}

@keyframes slide {
    0% {margin-top: calc(var(--containerh)*-4);}
    5% {margin-top: calc(var(--containerh)*-2);}
    50% {margin-top: calc(var(--containerh)*-2);}
    55% {margin-top: 0px;}
    100% {margin-top: 0px;}
}


@media (max-width:900px) {

h3 {
  font-size: 2rem;
}

.spinner-container {
  --containerh: 50px;
}

}

@media (max-width:600px) {

.slider-text1, .slider-text2, .spinner-phrase {
  display: none;
}

.slider-text3, .slider-text4 {
  display: block;
}

.spinner-container {
  --containerh: 90px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  align-items: center;
}

.slider-container {
  padding: 0;
}

.slider-container h3 {
    text-align: center;
    width: 100%;
}

}
</style>