<template>
  <div id="services-menu-section" class="section services-menu">
    <div class="color-container">
    <div class="content">
    <h3 class="section-title">Nuestros servicios</h3>
    <div class="services-list">
      <router-link id="design-item" :to="{name:'Initial', hash:'#design'}">
        <div class="services-list__item">
          <div>
            Diseño
          <span class="details-button">saber más <span class="material-icons arrow-icon-right">chevron_right</span></span>
          </div>
        </div>
      </router-link>
      <router-link id="strategy-item" :to="{name:'Initial', hash:'#strategy'}">
        <div class="services-list__item">
          <div>
            Estrategia
          <span class="details-button">saber más <span class="material-icons arrow-icon-right">chevron_right</span></span>
          </div>
        </div>
      </router-link>
      <router-link id="production-item" :to="{name:'Initial', hash:'#production'}">
        <div class="services-list__item">
          <div>
            Producción
          <span class="details-button">saber más <span class="material-icons arrow-icon-right">chevron_right</span></span>
          </div>
        </div>
      </router-link>
      <router-link id="web-item" :to="{name:'Initial', hash:'#web'}">
        <div class="services-list__item">
          <div>
            Desarrollo Web  
          <span class="details-button">saber más <span class="material-icons arrow-icon-right">chevron_right</span></span>
          </div>
        </div>
      </router-link>
    </div>
  </div>
    </div>
  </div>
</template>

<style scoped>

.color-container {
  width: 100%;
  height: 100%;
}

.section-title {
  color: var(--white);
  letter-spacing: 5px;
  text-transform: uppercase;
  text-align: center;
  margin-top: 0;
}

.content {
  max-width: 1100px;
  min-width: 700px;
  width: 75%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.services-list {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 50% 50%;
  list-style-type: none;
  margin: 30px auto;
  padding: 0;
  height: 100%;
  width: 100%;
  min-height: 400px;
  max-height: 600px;
}

.services-list a {
  text-decoration: none;
}

.services-list__item {
  list-style-position: outside;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.services-list__item div {
  position: relative;
  color: var(--white);
  font-size: 2.5rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 700;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
  height: 100%;
  width: 100%;
}

.details-button {
  font-weight: 400;
  font-size: 1.2rem;
  color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 400ms 0ms;
}

.arrow-icon-right {
  padding: 0;
  margin: 0;
  color: inherit;
  font-size: inherit;
}

.services-list__item:hover .details-button {
  opacity: 1;
  transition: opacity 600ms 0ms;
}

#design-item {
  background-image: url('../.././assets/Images/PreviousProyects/design-service.png');
  background-size: cover;
  background-position: center;
  background-color: rgb(0, 0, 0);
}

#strategy-item {
  background-image: url('../.././assets/Images/PreviousProyects/strategy-service.png');
  background-size: cover;
  background-position: center;
  background-color: rgb(0, 0, 0);
}

#production-item {
  background-image: url('../.././assets/Images/PreviousProyects/production-service.png');
  background-size: cover;
  background-position: center;
  background-color: rgb(0, 0, 0);
}

#web-item {
  background-image: url('../.././assets/Images/PreviousProyects/web-service.png');
  background-size: contain;
  background-position: center;
  background-color: var(--white);
}


@media (max-height: 900px) {
   .services-menu {
    padding: 200px 0px;
  }

  .services-list {
  min-height: 350px;
  max-height: 500px;
}

.services-list__item div {
  font-size: 2rem;
}

.section-title {
  margin-bottom: 15px;
}

}

@media (max-width:850px) {

  .content {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-width: 0;
  }

  .section-title {
    font-size: 2.2rem;
    margin-right: 0;
  }

  .services-list {
    grid-template-columns: 100%;
    grid-template-rows: 150px 150px 150px 150px;
  }

  .services-list__item div{
    font-size: 1.8rem;
  }

  .details-button {
    opacity: 1;
  }

}

</style>