<template>
  <div class="web-section section">
    <h3 class="section-title">Desarrollo Web </h3>
    <div class="services-list">
      <p class="description-row">
        <span class="bold">Páginas Web</span> de diferentes tipos, como landing
        pages, portafolios, formularios de contacto, menús digitales, entre
        otras. Nosotros nos encargamos de digitalizar tu idea, poniendo siempre
        por delante la experiencia del usuario y la identidad de tu marca.
      </p>
      <p class="description-row">
        <span class="bold">E-Commerce</span> utilizando soluciones desde cero o
        Shopify para que puedas vender tus productos y hacer crecer tu negocio
        junto con tu desarrollo tecnológico.
      </p>
      <p class="description-row">
        <span class="bold">Plataformas Digitales</span> para programas de
        capacitación, eventos, y cualquier otra aplicación que requiera de
        registro de usuarios o almacenar datos. Somos expertos en innovación y
        adaptación de ideas para crear soluciones de alta tecnología.
      </p>
      <p class="description-row">
        <span class="bold">Sistemas empresariales</span> como redes sociales
        internas, organización intraempresarial o innovaciones empresariales.
        Nosotros te guiamos con nuestras innovaciones digitales para que tu
        proyecto crezca, alineándonos a tu visión empresarial.
      </p>
    </div>
    <contact-button class="contact-margin"></contact-button>
  </div>
</template>

<script>
import ContactButton from "../GeneralUI/ContactButton.vue"

export default {
  components: {
    ContactButton,
  }
}
</script>


<style scoped>

.web-section {
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.section-title {
 color: var(--white);
  letter-spacing: 5px;
  text-transform: uppercase;
  text-align: center;
  margin: 0 ;
}

.services-list {
  max-width: 1000px;
  margin: 15px auto;
  padding: 0;
}

.description-row {
  margin: 15px 20px;
  border-top: 1px solid white;
  padding: 15px 20px;
  line-height: 1.8;
}

.bold {
  font-weight: 800;
}


@media (max-height: 900px) {
  
   .web {
    padding: 200px 20px;
  }

  .contact-margin {
    margin-bottom: 100px;
  }

}

@media (max-width:850px) {

  .web-section{
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .section-title {
    font-size: 2.2rem;
    margin-right: 0;
    
  }

  .description-row {
    margin: 10px 0;
  }

}

</style>