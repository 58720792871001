import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Contact from "../views/Contact.vue";
import Services from "../views/Services.vue";
import About from "../views/About.vue";
import NotFound from "../views/NotFound.vue";
import BrandingTab from "../components/ServicesUI/DesignSectionWindows/BrandingTab.vue"
import Initial from "../components/ServicesUI/DesignSectionWindows/Initial.vue";
import InteriorTab from "../components/ServicesUI/DesignSectionWindows/InteriorTab.vue";
import CampaignsTab from "../components/ServicesUI/DesignSectionWindows/CampaignsTab.vue";
import PackageTab from "../components/ServicesUI/DesignSectionWindows/PackageTab.vue";
import Thanks from "../views/Thanks.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { transition: 'slide-to-side' }
  },
  {
    path: "/nosotros",
    name: "About",
    component: About,
    meta: { transition: 'slide-to-side' }
  },
  {
    path: "/servicios",
    name: "Services",
    redirect: "/servicios/initial",
    component: Services,
    children: [
      {
        path: "branding",
        name: "BrandingTab",
        component: BrandingTab,
        meta: { transition: 'slide' }
      },
      {
        path: "initial",
        name: "Initial",
        component: Initial,
        meta: { transition: 'slide-to-side' }
      },
      {
        path: "interior",
        name: "InteriorTab",
        component: InteriorTab,
        meta: { transition: 'slide' }
      },
      {
        path: "campaigns",
        name: "CampaignsTab",
        component: CampaignsTab,
        meta: { transition: 'slide' }
      },
      {
        path: "package",
        name: "PackageTab",
        component: PackageTab,
        meta: { transition: 'slide' }
      },

    ],
  },
  {
    path: "/contacto",
    name: "Contact",
    component: Contact,
    meta: { transition: 'slide-to-side' }
  },
  {
    path: "/gracias",
    name: "Thanks",
    component: Thanks,
    meta: { transition: 'slide-to-side' }
  },
  {
    path: '/:notFound(.*)',
    name: "NotFound",
    component: NotFound,
    meta: { transition: 'slide-to-side' }
  },
];

function wait(duration) {
  return new Promise((resolve) => setTimeout(resolve, duration));
}

async function tryScrollToAnchor(hash, timeout = 1000, delay = 100) {
  while (timeout > 0) {
    const el = document.querySelector(hash);
    if (el) {
      el.scrollIntoView({ behavior: "smooth" });
      break;
    }
    await wait(delay);
    timeout = timeout - delay;
  }
};

const scrollBehavior = (to, savedPosition) => {
  if (to.hash) {
    tryScrollToAnchor(to.hash, 1000, 100);
  }

  if (savedPosition) {
    return savedPosition;
  }

  else {
    new Promise((resolve) =>{
      setTimeout(() => {
        resolve({left:0, top:0, behavior: 'smooth'}, 500)
      });
    })
  }

};

const router = createRouter({
  scrollBehavior,
  history: createWebHistory(process.env.BASE_URL),
  routes,
  
});

export default router;