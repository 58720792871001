<template>
  <div id="homepage-about-section" class="section about">
    <div class="content">
      <div class="upper-info">
        <h3 class="section-title">
          AGENCIA DE DISEÑO Y PRODUCCIÓN DE MATERIALES PUBLICITARIOS
        </h3>
        <p class="description">
          Somos una agencia regiomontana con más de 10 años de experiencia, donde
          fusionamos la creatividad y el diseño con la producción de materiales
          publicitarios de amplia gama.
        </p>
      </div>
      <div class="clients-banner">
        <h6 class="clients-phrase">Trabajamos con los mejores:</h6>
        <div class="scroller">
        <div class="left-button button" @click="scroll_left">
          <span class="material-icons">chevron_left</span>
        </div>
        <div class="clients-logos-wrapper">
        <div behavior="scroll" direction="left" class="clients-logos">
          <!-- <img
            class="logo"
            src="../.././assets/Images/ClientsLogos/client-logo-1.png"
            alt="client-logo-sumerca"
          /> -->
          <img
            class="logo"
            src="../.././assets/Images/ClientsLogos/client-logo-2.png"
            alt="client-logo-british-tobacco"
          />
          <img
            class="logo"
            src="../.././assets/Images/ClientsLogos/client-logo-3.png"
            alt="client-logo-el-castellano"
          />
          <img
            class="logo"
            src="../.././assets/Images/ClientsLogos/client-logo-4.png"
            alt="client-logo-foliatti-casino"
          />
          <img
            class="logo"
            src="../.././assets/Images/ClientsLogos/client-logo-5.png"
            alt="client-logo-metalsa"
          />
          <img
            class="logo"
            src="../.././assets/Images/ClientsLogos/client-logo-6.png"
            alt="client-logo-senda"
          />
        </div>
        </div>
        <div class="right-button button" @click="scroll_right">
          <span class="material-icons">chevron_right</span>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    scroll_left() {
      let content = document.querySelector(".clients-logos-wrapper");
      content.scrollLeft -= 150;
    },
    scroll_right() {
      let content = document.querySelector(".clients-logos-wrapper");
      content.scrollLeft += 150;
    }
  }
};
</script>

<style scoped>
.about {
  padding: 40px;
}

.section-title {
  color: var(--white);
  letter-spacing: 5px;
  text-transform: uppercase;
  word-wrap: break-word;
  margin: 0;
  margin-right: 20px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1000px;
  margin: 0 auto;
  height: 100%;
}

.upper-info {
  display: grid;
  grid-template-columns: 60% 40%;
  align-items: center;
  margin-bottom: 20px;
}

.description {
  line-height: 2;
  margin: 0;
  border-left: 2px solid var(--brightblue);
  padding: 10px 0;
  padding-left: 20px;
}

.clients-banner {
  width: 100%;
  margin-top: 20px;
  
}

.clients-phrase {
  text-align: center;
  margin-bottom: 40px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  
}

.scroller {
  display: flex;
  align-items: center;
}

.button {
  cursor: pointer;
}

.button:hover span {
  color: var(--brightblue);
}


.button span {
  font-size: 2.5rem;
}

.button span:focus {
  outline: none;
}

.button span::selection {
  background: none;
}

.right-button {
  margin-left: 15px;
}

.left-button {
  margin-right: 15px;
}

.clients-logos-wrapper {
    width: 100%;
    overflow-x: scroll;
    scroll-behavior: smooth;
}

.clients-logos-wrapper::-webkit-scrollbar {
  width: 0px;
}

.clients-logos {
  overflow-x: visible;
  display: flex;
  flex-shrink: 0;
  flex-wrap: nowrap;
  align-items: center;
  height: 80px;
  width: 100%;
}

.logo {
  height: 50px;
  margin: 0 40px;
}

@keyframes logos-slide {

    0% {transform: translateX(0%);}
    100% {transform: translateX(-100%);}

}

@media (max-height: 900px) {
  .about {
    padding: 200px 20px;
  }
}

@media (max-width: 850px) {
  .upper-info {
    grid-template-columns: 100%;
    margin-bottom: 10px;
  }

  .clients-banner {
  margin-top: 0px;
}

  .clients-phrase {
  margin-bottom: 20px;
  margin-top: 20px;
}

.description {
  border: none;
  border-top: 2px solid var(--brightblue);
  margin-top: 10px;
  padding: 0;
  padding-top: 10px;
  
}

.button span {
  font-size: 1.5rem;
}


.right-button {
  margin-left: 10px;
}

.left-button {
  margin-right: 10px;
}

.logo {
  height: 30px;
}

.clients-logos {
  height: 40px;
}

  .section-title {
    font-size: 2.2rem;
    margin-right: 0;
    margin-bottom: 15px;
  }
}
</style>