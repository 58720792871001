<template>
  <div class="page-wrapper">
    <div class="color-container">
      <div class="content">
        <h3 class="section-title">
          ¡Gracias por contactarnos! Responderemos lo más breve posible.
        </h3>
        <div class="back-button">
          <router-link to="/" class="button-router-link"
            >Volver al inicio</router-link
          >
        </div>
      </div>
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import Footer from "../components/Footer.vue";

export default {
  components: {
    Footer,
  },
};
</script>

<style lang="scss" scoped>
.page-wrapper {
  overflow-y: scroll;
  background-color: rgba(0, 255, 255, 0.788);
}

.color-container {
  background-image: url(".././assets/Images/noise.png"),
    linear-gradient(var(--darkbackground), rgba(0, 0, 0, 0)),
    linear-gradient(to top left, var(--darkbackground), rgba(0, 0, 0, 0)),
    linear-gradient(to top left, var(--darkgray), rgba(0, 0, 0, 0));
  background-repeat: repeat;
  background-blend-mode: screen;
}

.content {
  height: var(--screen-size);
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}

.section-title {
  color: var(--white);
  margin-top: 0;
  text-align: center;
}


.button-router-link {
  text-decoration: none;
  font-size: 1rem;
  letter-spacing: 3px;
  font-weight: 600;
  padding: 10px 30px;
  border: 1px solid var(--white);
  border-radius: 12px;
  transition: all ease 0.3s;
}

.button-router-link:hover {
  border-color: var(--accent1);
  color: var(--accent1);
}

.back-button {
  font-size: 1.2rem;
  text-align: left;
}

@media (max-width: 850px) {
  .content {
    padding: 40px 20px;
  }

  .section-title {
    font-size: 2.2rem;
  }


}
</style>