<template>
  <div class="tab-wrapper">
    <h1 class="design-marquee">Design Archives</h1>
    <div class="services-list">
      <router-link class="router-link" to="/servicios/branding">
        <div class="services-list__item hover-left" id="branding">Branding</div>
      </router-link>
      <router-link class="router-link" to="/servicios/interior">
        <div class="services-list__item hover-right" id="interior">
          Interiorismo
        </div>
      </router-link>
      <router-link class="router-link" to="/servicios/campaigns">
        <div class="services-list__item hover-left" id="company">Campañas</div>
      </router-link>
      <router-link class="router-link" to="/servicios/package">
        <div class="services-list__item hover-right" id="package">Empaques</div>
      </router-link>
    </div>
    <h2>Elige una opción</h2>
    <h2 class="continue-message">continúa explorando<span class="material-icons">keyboard_arrow_down</span></h2>
    
  </div>
</template>

<style scoped>
.tab-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  overflow: hidden;
}

.design-marquee {
  margin: 0 auto;
  font-weight: 600;
  letter-spacing: 20px;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: visible;
  animation: marquee 10s linear infinite;
  padding-left: 100%;
}

h2 {
  margin: 0;
  font-size: 2rem;
  margin-top: 30px;
}

.services-list {
  display: grid;
  grid-template-columns: 23% 23% 23% 23%;
  column-gap: 15px;
  width: 100%;
  justify-content: center;
  margin-top: 30px;
  border-bottom: 1px solid var(--white);
}

.services-list__item {
  list-style-position: outside;
  font-size: 1.5rem;
  font-weight: 600;
  width: 100%;
  padding: 10px 10px;
  margin: 0px auto;
  text-align: center;
  cursor: pointer;
  border: 1px solid var(--white);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: none;
}

.hint {
  font-weight: 300;
  font-size: 1rem;
}

.router-link {
  text-decoration: none;
}

.router-link:hover .hover-right {
  color: var(--accent1);
  transform: translateY(-10px);
  transition: all 0.3s ease;
}

.router-link:hover .hover-left {
  color: var(--accent1);
  transform: translateY(-10px);
  transition: all 0.3s ease;
}

.router-link-exact-active .services-list__item {
  color: var(--accent1);
}

.continue-message {
  display: none;
}

@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}

@media (max-height: 750px) {
  .continue-message {
  position: absolute;
  bottom: 10px;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 0;
}
}

@media (max-width: 850px) {

  .design-marquee {
    margin-top: 40px;
  }

  .services-list {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 25% 25% 25% 25%;
    margin-top: 60px;
  }

  .services-list__item {
    width: 100%;
    text-align: left;
  }

  h2 {
    margin-top: 15px;
    font-size: 1.8rem;
    margin-bottom: 50px;
  }

  #branding {
    transform: translateX(40px);
  }
  #interior {
    transform: translateX(30px);
  }
  #company {
    transform: translateX(20px);
  }
  #package {
    transform: translateX(10px);
  }

  .continue-message {
  position: absolute;
  bottom: 10px;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 0;
}

.continue-message span {
  margin-left: 5px;
}

}
</style>