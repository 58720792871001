<template>
  <div id="strategy" class="strategy-section section">
    <div class="services-list">
      <div class="card-container" @click="flipCard1 = !flipCard1">
        <div
          class="services-list__card"
          id="item1"
          v-bind:class="{ slideright: flipCard1}"
        >
          <div class="front">
            <h6 class="strategy-title">01 Estrategia</h6>
            Activaciones comerciales y corporativas
            <h6 class="details-button">
              saber más
              <span class="material-icons arrow-icon-right">chevron_right</span>
            </h6>
          </div>
          <div class="back">
            <p class="back-title">
              Traspasa la barrera para crear experiencias
            </p>
            <p>
              Las activaciones son un recurso que se ha vuelto esencial para
              despertar interés de nuestra audiencia meta estimulando sus
              sentidos más allá de la vista. Parasol es la agencia que te
              ayudará a aterrizar una estrategia, diseñar y producir estas
              experiencias en pequeña o gran escala. Contamos con equipo de
              diseño, producción, video y fotografía para ejecutar tus
              activaciones de manera integral.
            </p>
            <router-link class="contact-router" to="/contacto">
              <h6 class="details-button contact">contactar</h6>
            </router-link>
            <img class="back-image" src="../.././assets/Images/PreviousProyects/tec-comunicacion.jpg" alt="tecnologico-de-monterrey-project-example">
          </div>
        </div>
      </div>

      <div class="card-container" @click="flipCard2 = !flipCard2">
        <div class="services-list__card" id="item2" v-bind:class="{ slideleft: flipCard2 }">
          <div class="front">
            <h6 class="strategy-title">02 Estrategia</h6>
            Creación de programas de comunicación interna
            <h6 class="details-button">
              saber más
              <span class="material-icons arrow-icon-right">chevron_right</span>
            </h6>
          </div>
          <div class="back">
            <p class="back-title">
              Una solución para todos tus objetivos empresariales
            </p>
            <p>
              A diferencia de una campaña que pudiera ser temporal. Los
              programas de comunicación se crean para quedarse. Te ayudamos a
              detectar las necesidades de tu empresa y colaboradores para crear
              programas impregnados con tu marca que se desarrollen a largo
              plazo. Queremos que tu empresa tenga rituales que ayuden a la
              adherencia de sus valores, visión y objetivos.
            </p>
            <router-link class="contact-router" to="/contacto">
              <h6 class="details-button contact">contactar</h6>
            </router-link>
            <img class="back-image" src="../.././assets/Images/PreviousProyects/program.png" alt="internal-communication-program-example">
          </div>
        </div>
      </div>

      <div class="card-container" @click="flipCard3 = !flipCard3">
        <div class="services-list__card" id="item3" v-bind:class="{ slideright: flipCard3 }">
          <div class="front">
            <h6 class="strategy-title">03 Estrategia</h6>
            Disminución a la resistencia del cambio
            <h6 class="details-button">
              saber más
              <span class="material-icons arrow-icon-right">chevron_right</span>
            </h6>
          </div>
          <div class="back">
            <p class="back-title">Los cambios son oportunidades</p>
            <p>
              Los cambios pueden resultar difíciles para los colaboradores
              cuando se trata de aprender nuevos caminos para llegar a la
              excelencia. Ayudamos con campañas, activaciones, instalaciones y
              diseño gráfico a navegar los cambios de manera sutil y lúdica. Con
              estrategias como la asignación de liderazgos o recompensas, se
              puede disminuir el estrés y percibir la carga de trabajo como lo
              que es, un paso más para llegar a grandes metas.
            </p>
            <router-link class="contact-router" to="/contacto">
              <h6 class="details-button contact">contactar</h6>
            </router-link>
            <img class="back-image" src="../.././assets/Images/PreviousProyects/reset.jpg" alt="tablet-with-web-app-example">
          </div>
        </div>
      </div>

      <div class="card-container" @click="flipCard4 = !flipCard4">
        <div class="services-list__card" id="item4" v-bind:class="{ slideleft: flipCard4 }">
          <div class="front">
            <h6 class="strategy-title">04 Estrategia</h6>
            Campañas de disminución de riesgos psicosociales
            <h6 class="details-button">
              saber más
              <span class="material-icons arrow-icon-right">chevron_right</span>
            </h6>
          </div>
          <div class="back">
            <p class="back-title">Sentirse bien por fuera y por dentro</p>
            <p>
              La salud mental y física son muy importantes para el buen
              desempeño de nuestros colaboradores. Los seres humanos nos
              desarrollamos mejor en un estado de bienestar. Asesorados por
              nuestros expertos en desarrollo humano empresarial y business
              mindfulness, planeamos estrategias acompañadas de una buena
              comunicación para aminorar todas las cosas que pudieran estar
              aquejando a las personas que hacen grandes a las empresas: la
              fuerza de nuestros colaboradores.
            </p>
            <router-link class="contact-router" to="/contacto">
              <h6 class="details-button contact">contactar</h6>
            </router-link>
            <img class="back-image" src="../.././assets/Images/PreviousProyects/campaigns.png" alt="office-with-enterprise-communication-example">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      flipCard1: false,
      flipCard2: false,
      flipCard3: false,
      flipCard4: false,
    };
  },
};
</script>


<style scoped>

.strategy-section {
  background-color: black;
  padding: 0;
}

.section-title {
  color: var(--white);
  margin-top: 0;
  text-align: center;
}

h6 {
  color: inherit;
  width: max-content;
  margin-bottom: 10px;
  font-size: 1rem;
  border-bottom: 2px solid currentColor;
  padding: 0 3px 3px 3px;
  text-transform: uppercase;
}

.services-list {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 50% 50%;
  list-style-type: none;
  padding-left: 0;
  margin: 0;
  height: var(--screen-size);
}

.card-container {
  cursor: pointer;
  outline: none;
}

.services-list__card {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* .flip .front {
  transition: transform 0.5s ease;
  transform: translateX(-100%);
} */

.slideleft .front {
  transition: transform 0.5s ease;
  transform: translateX(100%);
}

.slideright .front {
  transition: transform 0.5s ease;
  transform: translateX(-100%);
}

.front,
.back {
  position: absolute;
  width: 100%;
  height: 100%;
}

.front:focus,
.back:focus,
.card-container:focus,
.services-list__card:focus,
.slideleft:focus,
.slideright:focus {
  outline: none !important;
}

.front {
  display: flex;
  flex-direction: column;
  padding: 40px 30px;
  font-weight: 700;
  font-size: 1.5rem;
  color: inherit;
  z-index: 100;
}

.back {
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
  font-weight: 500;
  color: inherit;
  opacity: 1;
  z-index: 90;
}

.back p {
  color: inherit;
}

.back-title {
  font-weight: 800;
  font-size: 1.8rem;
  margin-bottom: 0px;
}

.details-button {
  position: absolute;
  right: 30px;
  bottom: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
  z-index: 200;
  color: inherit;
}

.contact-router {
  color: inherit;
}

.contact {
  transition: transform 0.5s ease;
}

.contact-router:hover .contact {
  transform: translateX(-10px); 
}

.details-button:hover {
  transform: translateX(-10px);
  transition: transform 0.5s ease;
}

.arrow-icon-right {
  color: inherit;
}

#item1 .front {
  background-color: var(--accent1);
  color: var(--background);
}

#item2 .front  {
  background-color: var(--white);
  color: var(--background);
}

#item3 .front {
  background-color: var(--darkgray);
}

#item4 .front {
  background-color: var(--accent2);
}

.back-image {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  object-position: center;
  z-index: -100;
}

#item1 .back .back-image{
  opacity: 0.3;
}

#item2 .back .back-image{
  opacity: 0.3;
}

#item3 .back .back-image{
  
  opacity: 0.2;
}

#item4 .back .back-image{
  opacity: 0.35;
  
}



 #item1 .back {
  background-color: var(--transaccent1);
  background-image: linear-gradient(to bottom right, var(--transaccent1) 40%, var(--background)), linear-gradient(to top right, var(--blue)30%, transparent);
  background-blend-mode:soft-light;
  color: white;
}

 #item2 .back {
  background-image: linear-gradient(to top left, var(--transwhite) 40%, var(--accent2)), linear-gradient(to bottom right, var(--white)10%, transparent);
  background-blend-mode:soft-light;
  background-color: var(--white);
  color: var(--background);
}

 #item3 .back {
  background-image: linear-gradient(to bottom right, var(--darkblue) 40%, var(--darkgray)), linear-gradient(to bottom right, var(--blue)10%, transparent), radial-gradient(ellipse at top left, var(--transwhite)10%, var(--transaccent1));
  background-blend-mode:soft-light;
  background-color: var(--darkgray);
  color: white;
}

 #item4 .back {
  background-image: linear-gradient(to bottom right, var(--darkblue) 40%, var(--accent1)), linear-gradient(to bottom right, var(--accent2)10%, transparent);
  background-blend-mode:soft-light;
  background-color: var(--transaccent2);
  color: white;
}


@media (max-height: 900px) {
  
.details-button {
  bottom: 10px;
}

.back-title {
  margin: 0;
  font-size: 1.5rem;
}


}

@media (max-width: 850px) {
  
.services-list {
  grid-template-columns: 100%;
  grid-template-rows: 350px 350px 350px 350px;
  height: 100%;
}
}


</style>