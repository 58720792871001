<template>
  <div class="production-section section">
    <div class="title-contact-container">
      <h3 class="section-title">Producción</h3>
      <contact-button></contact-button>
    </div>
    <div class="services-list">
      <div class="description-column" id="column1">
        <h4>01</h4>
        <p>
          <span class="bold">Impresion e instalación</span> de lona.
        </p>
      </div>

      <div class="description-column" id="column2">
        <h4>02</h4>
        <p>
          <span class="bold">Recorte e impresión</span> de vinil, estireno, telas,
          canvas, poster y papel.
        </p>
      </div>

      <div class="description-column" id="column3">
        <h4>03</h4>
         <p>
        <span class="bold">Corte, grabado y relieve</span> en madera, melamina,
        acrílico, mdf, aluminio, trovicel, estireno, corolas y alucobond.
      </p>
      </div>

      <div class="description-column" id="column4">
        <h4>04</h4>
        <p>
        <span class="bold">
          Rotulación vehicular, cajas de luz, bastidores, pendones, exhibidores,
          estructuras, letras y logotipos planos o 3D.</span
        >
      </p>
      </div>

      <div class="description-column" id="column5">
        <h4>05</h4>
        <p>
        <span class="bold"> Impresión, recorte y aplicaciones</span> de
        plásticos, metales, imanes y alucobond.
      </p>
      </div>
      
    </div>
  </div>
</template>

<script>
import ContactButton from "../GeneralUI/ContactButton.vue";

export default {
  components: {
    ContactButton
  }
}
</script>


<style scoped>

.production-section {
  padding: 0 40px;
  display: flex;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
}

.section-title {
  color: var(--white);
  letter-spacing: 5px;
  text-transform: uppercase;
  width: min-content;
  margin: 20px 0;
  word-wrap: break-word;
}

.title-contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 40px;
}

.services-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0;
    padding: 0;
}

.description-column {
    border: 1px solid var(--white);
    padding: 15px 20px;
    margin: 15px 0;
    border-radius: 15px;
    width: 100%;
    display: flex;
    align-items: center;
}


.description-column h4 {
  margin: 0;
  margin-right: 20px;
  padding-right: 20px;
  border-right: 2px solid var(--white);
  font-weight: 400;
}

.bold {
    font-weight: 800;
    color: inherit;
}


@media (max-height: 900px) {
  
   .production-section {
    padding: 200px 20px;
  }

}

@media (max-width:850px) {

  .production-section{
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .section-title {
    font-size: 2.2rem;
    margin-right: 0;
    
  }

.title-contact-container {
  margin-bottom: 20px;
  margin-right: 0px;
}

}

</style>