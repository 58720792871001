<template>
  <form action="https://formsubmit.co/info@parasolprints.com" method="POST" class="contact-form" id="contact">
    <label for="email">Correo electrónico*</label>
    <input name="email" id="email" type="email" required>

    <label for="name">Nombre*</label>
    <input name="name" id="name" type="text" required>

    <label for="subject">Asunto*</label>
    <select name="subject" id="subject" required>
      <option value="Web Development">Desarrollo Web</option>
      <option value="Grafic Design">Diseño gráfico</option>
      <option value="Market Estrategies">Estrategias de mercado</option>
      <option value="Production">Impresión, recorte y producción</option>
    </select>

    <label for="message">Mensaje*</label>
    <textarea maxlength="1000" name="message" id="message" cols="30" rows="10" required></textarea>
    
    <input type="hidden" name="_next" value="https://parasolestudio.com/gracias">

    <input id="submit-button" type="submit" value="enviar" required />
  </form>
</template>

<style scoped>
.contact-form {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
}

label {
  color: var(--white);
  font-weight: 600;
  margin-bottom: 5px;
}

input,
textarea,
select,
option {
  background: var(--white);
  outline: none;
  border: none;
  border-radius: 12px;
  padding: 8px 20px;
  color: var(--background);
  margin-bottom: 15px;
}

option {
    cursor: pointer;
}


textarea {
  resize: none;
  font-family: "Raleway", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
}

#submit-button {
  font-size: 1rem;
  letter-spacing: 3px;
  font-weight: 600;
  color: var(--white);
  border: 1px solid var(--white);
  background-color:transparent;
  cursor: pointer;
  padding: 20px;
  max-width: 300px;
  min-width: 200px;
  place-self: flex-end;
}

#submit-button:hover {
  border-color: var(--accent1) ;
  color: var(--accent1);
  transition: all 0.3s ease;
}
</style>
