<template>
  <div class="tab-wrapper">
    <div class="text-section">
      <h4 class="section-title">Una idea que se vuelve realidad</h4>
      <p>
        Una marca nace como una idea que tiene que ser vista. Te ayudamos a
        convertir tus ideas en símbolos, palabras y colores que atraigan la
        atención de tus próximos clientes. Contamos con un equipo de
        storytellers gráficos, que no solo diseñan si no que transforman tus
        ideas en una historia.
      </p>
      <p>
        Investigamos el mercado, analizamos sus fortalezas y debilidades,
        identificamos las áreas de oportunidad, aterrizamos un concepto creativo
        y después lo transformamos en la imagen para tu idea de marca.
      </p>
      <div class="buttons-container">
        <contact-button></contact-button>
        <initial-button></initial-button>
      </div>
    </div>

    <div class="image-container">
      <img
        class="logos-image"
        src="../../../assets/Images/PreviousProyects/branding.jpeg"
        alt="branding-example-image"
      />
    </div>
  </div>
</template>

<script>
import ContactButton from "../../GeneralUI/ContactButton.vue";
import InitialButton from "../../GeneralUI/InitialButton.vue";

export default {
  components: {
    ContactButton,
    InitialButton,
  },
};
</script>


<style scoped>
.tab-wrapper {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 100%;
  overflow: hidden;
  position: relative;
}

.text-section {
  padding: 20px;
}

.section-title {
  margin: 20px 0;
}

.logos-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.buttons-container {
  position: absolute;
  left: 20px;
  bottom: 30px;
  width: 100%;
  margin-top: 20px;
  display: flex;
  column-gap: 30px;
}

@media (max-width: 850px) {
  .section-title {
    font-size: 2rem;
    margin-right: 0;
  }

  .tab-wrapper {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 100%;
    place-items: center;
    overflow: hidden;
    position: relative;
  }

  .buttons-container {
    position: relative;
    flex-direction: column;
    left: 0;
    bottom: 0;
    row-gap: 40px;
    margin: 30px auto;
    height: 100%;
  }

  .text-section {
    z-index: 100;
    overflow-y: auto;
  }

  .logos-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.4;
  }

  .image-container {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-color: black;
  }
}
</style>