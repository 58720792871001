<template>
     <div class="logo-banner section">
      <img
        class="logo-banner__logo"
        src="../.././assets/Branding/parasol-logo-yellow.png"
        alt="parasol-company-logo-yellow"
      />
      <phrase-spinner></phrase-spinner>
      <a class="scroll-down" href="#homepage-about-section">
          <h5>
            Ház scroll down para explorar <span class="material-icons">mouse</span>
          </h5>
          <h5><span class="material-icons down-arrow">arrow_downward</span></h5>
      </a>
    </div>
</template>

<script>
import PhraseSpinner from "./PhraseSpinner.vue";

export default {
    components: {
        PhraseSpinner
    }
}
</script>

<style scoped>

.logo-banner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: var(--screen-size);
}

.logo-banner__logo {
  width: 800px;
  margin: 0;
}


.scroll-down {
  position: absolute;
  bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  color: var(--darkbackground);
}

.scroll-down h5 {
  color: inherit;
  margin-bottom: 15px;
  margin-top: 0;
  font-weight: 400;
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.scroll-down span {
  color: inherit;
}

.scroll-down:hover .down-arrow {
  opacity: 1;
}

.down-arrow {
  color: inherit;
  font-size: 3rem;
  margin-top: 0;
  opacity: 0;
  transition: opacity 0.8s ease;
}

@media (max-height: 900px) {
  .scroll-down h5 {
  font-size: 1.2rem;
  margin-bottom: 5px;
}

.scroll-down span {
  font-size: 2rem;
}
}

@media (max-width: 1000px) {
  .logo-banner__logo {
    width: 500px;
  }
}


@media (max-width: 850px) {

  .logo-banner__logo {
    width: 80%;
    margin: 0px auto 20px auto;
  }

 .scroll-down h5 {
    font-size: 1.5rem;
  }

}

@media (max-width: 600px) {

  .down-arrow {
    opacity: 1;
  }

}

</style>