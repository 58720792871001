<template>
  <main class="page-wrapper" id="services-page">

    <div class="sections-container">
      <design-section id="design" class="section"></design-section>
      <strategy-section id="strategy" class="strategy section"></strategy-section>
      <production-section id="production" class="section"></production-section>
      <web-section id="web" class="section"></web-section>
    </div>
    <Footer class="footer"></Footer>

  </main>
</template>

<script>
import DesignSection from "../components/ServicesUI/DesignSection.vue";
import StrategySection from "../components/ServicesUI/StrategySection.vue";
import ProductionSection from "../components/ServicesUI/ProductionSection.vue";
import WebSection from "../components/ServicesUI/WebSection.vue";
import Footer from "../components/Footer.vue";


export default {
  components: {
    DesignSection,
    StrategySection,
    ProductionSection,
    WebSection,
    Footer
  },
}
</script>


<style lang="scss" scoped>

#services-page {
  background-image: linear-gradient(to bottom, var(--blue), var(--darkblue));
}

.sections-container {
  background-image: url('.././assets/Images/noise.png'), radial-gradient(circle at top left, var(--accent2), rgba(0, 0, 0, 0)40%),radial-gradient(ellipse at right, rgba(0, 255, 255, 0.363), transparent),linear-gradient(to top, var(--background), rgba(0, 0, 0, 0));
  background-repeat: repeat;
  background-blend-mode:screen; 
}




</style>