<template>
  <div id="app" style="height: 100%">
    <link
      href="https://fonts.googleapis.com/icon?family=Material+Icons"
      rel="stylesheet"
    />
    <NavBar class="nav-bar" id="navBar"></NavBar>
    <router-view v-slot="{Component, route}" id="page-content">
    <transition :name="route.meta.transition" mode="out-in">
      <component :is="Component"></component>
    </transition>
    </router-view>
  </div>
</template>
<script>
import NavBar from "./components/NavBar.vue";
// import Footer from "./components/Footer.vue";

export default {
  name: "App",
  components: {
    NavBar,
    // Footer,
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --blue: #292e61;
  --brightblue:#2e40ff ;
  --darkblue:#00085f;
  --darkbackground: #0a0b14;
  --background: #101227;
  --libackground: #4850a7;
  --accent1: #f9bb30;
  --accent2: #d76d2d;
  --darkaccent2:#4b1f05;
  --gray: #333333;
  --darkgray: #2b2b2b;
  --white: #f7f3e3;
  --error: #e27373;
  
  --screen-size: calc(100vh - 80px);

  --transwhite: #f7f3e33a;
  --transaccent2: #d76e2d9f;
  --transaccent1: #f9b93075;

  font-size: 16px;
}

* {
  box-sizing: border-box;
  color: var(--white);
}

#app {
  font-family: "Raleway", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  overflow: hidden;
  background-color: var(--darkbackground);
}

#page-content {
  height: calc(100% - 80px);
}

#page-content::-webkit-scrollbar {
  width: 0.75rem;
}

#page-content::-webkit-scrollbar-track {
  background: #1e1e24;
}

#page-content::-webkit-scrollbar-thumb {
  background: var(--white);
}
.nav-bar {
  z-index: 100;
}

body,
html {
  margin: 0;
  height: 100vh;
  width: 100%;
}

body {
  background: var(--background);
}

h1 {
  font-size: 6.5rem;
  font-weight: lighter;
  letter-spacing: -1.5px;
}

h2 {
  font-size: 4rem;
  font-weight: lighter;
  letter-spacing: -0.5px;
}

h3 {
  font-size: 3.25rem;
}

h4 {
  font-size: 2.3rem;
  letter-spacing: 0.25px;
}

h5 {
  font-size: 1.625rem;
}

h6 {
  font-size: 1.375rem;
  font-weight: 600;
  letter-spacing: 0.15px;
}

p {
  font-size: 1rem;
  letter-spacing: 0.5px;
}

li {
  font-size: 1rem;
  letter-spacing: 0.5px;
}

button {
  text-transform: uppercase;
  font-size: 1.2rem;
  letter-spacing: 1.25px;
}

label {
  font-size: 1.1rem;
}

input,
textarea {
  font-size: 1rem;
  color: var(--gray);
}

.error {
  color: var(--error);
}

/* Rules for sizing the icon. */
.material-icons.md-18 {
  font-size: 18px;
}
.material-icons.md-24 {
  font-size: 24px;
}
.material-icons.md-36 {
  font-size: 36px;
}
.material-icons.md-48 {
  font-size: 48px;
}

/* Rules for using icons as black on a light background. */
.material-icons.md-dark {
  color: rgba(0, 0, 0, 0.54);
}
.material-icons.md-dark.md-inactive {
  color: rgba(0, 0, 0, 0.26);
}

/* Rules for using icons as white on a dark background. */
.material-icons.md-light {
  color: rgba(255, 255, 255, 1);
}
.material-icons.md-light.md-inactive {
  color: rgba(255, 255, 255, 0.3);
}

.slide-to-side-enter-active,
.slide-to-side-leave-active {
  transition:opacity 0.5s;
}

.slide-to-side-enter-from,
.slide-to-side-leave-to {
  opacity: 0;
}


.page-wrapper {
  height: var(--screen-size);
  scroll-snap-type: y proximity;
  overflow-y: scroll;
  scroll-behavior: smooth;
  width: 100vw;
  overflow-x: hidden;
}

.section {
    height: var(--screen-size);
    scroll-snap-align: start;
}

@media (max-height: 900px), (max-width: 850px) {
  :root {
    font-size: 14px;
  }

  #page-content::-webkit-scrollbar {
  width: 0.5rem;
  }


.page-wrapper {
  height: 100%;
  scroll-snap-type:initial;
  overflow-y: scroll;
  scroll-behavior: smooth;
  width: 100vw;
  overflow-x: hidden;
}

.section {
    height: 100%;
    scroll-snap-align: initial;
}

}


</style>
