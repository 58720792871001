<template>
  <main class="page-wrapper home-page" id="home-page">
    <div class="color-container">

    <logo-banner class="section"></logo-banner>
    <homepage-about class="section"></homepage-about>
    <methdology-section
      id="methodology-section"
      class="section"
    ></methdology-section>
    <services-menu class="section"></services-menu>
    <homepage-contact class="section"></homepage-contact>
    </div>
    <Footer></Footer>
  </main>
</template>

<script>
import MethdologySection from "../components/HomeUI/MethdologySection.vue";
import ServicesMenu from "../components/HomeUI/ServicesMenu.vue";
import HomepageContact from "../components/HomeUI/HomepageContact.vue";
import HomepageAbout from "../components/HomeUI/HomepageAbout.vue"
import Footer from "../components/Footer.vue";
import LogoBanner from "../components/HomeUI/LogoBanner.vue";

export default {
  components: {
    MethdologySection,
    ServicesMenu,
    HomepageContact,
    HomepageAbout,
    Footer,
    LogoBanner,
  },
};
</script>


<style lang="scss" scoped>


.color-container {
  background-image: url('.././assets/Images/noise.png'), radial-gradient(circle at top,var(--accent2), rgba(0, 0, 0, 0)),  linear-gradient(to top, var(--darkblue)5%, transparent);
  background-repeat: repeat;
  background-blend-mode:screen; 
}

.home-page {
  background-image: linear-gradient(to bottom, cyan, var(--darkgray));
}



</style>
