<template>
    <div class="contact-button">
      <router-link to="/contacto" class="contact-router-link">Contactar</router-link>
    </div>
</template>

<style scoped>

.contact-router-link {
  text-decoration: none;
  font-size: 1rem;
  letter-spacing: 3px;
  font-weight: 600;
  padding: 10px 30px;
  border: 1px solid var(--white);
  border-radius: 12px;
  transition: all ease 0.3s;
}

.contact-router-link:hover {
  border-color: var(--accent1);
  color: var(--accent1);
}

.contact-button {
  font-size: 1.2rem;
  text-align: center;
}

</style>