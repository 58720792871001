<template>
  <div class="page-wrapper">
  <div class="color-container">
    <contact-section></contact-section>
    <!-- <div class="lower-section">
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3593.829578154746!2d-100.28779278446953!3d25.743148815649846!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8662953056df19a9%3A0xdfd7291fcc9f09cc!2sTim%20Hortons%20San%20Nicol%C3%A1s-%20Drive%20Thru!5e0!3m2!1ses-419!2smx!4v1626401567241!5m2!1ses-419!2smx" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
    </div> -->
    <Footer></Footer>
    </div>
  </div>

</template>

<script>
import Footer from "../components/Footer.vue";
import ContactSection from "../components/GeneralUI/ContactSection.vue"

export default {
  components: {
    Footer,
    ContactSection,
  },
};
</script>

<style scoped>
.page-wrapper {
 overflow-y: scroll;
 background-color: var(--accent2);
}

.color-container {
  background-image: url('.././assets/Images/noise.png'),linear-gradient(var(--darkbackground), rgba(0, 0, 0, 0)), linear-gradient(to top left, var(--darkbackground), rgba(0, 0, 0, 0)), linear-gradient(to top left, var(--darkgray), rgba(0, 0, 0, 0));
 background-repeat: repeat;
 background-blend-mode:screen;
}



</style>