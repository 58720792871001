<template>
  <div id="homepage-contact-section section">
    <contact-section></contact-section>
  </div>
</template>

<script>
import ContactSection from "../GeneralUI/ContactSection.vue";

export default {
    components:{
        ContactSection,
    }
}

</script>


<style scoped>


.section-title {
  color: var(--white);
  margin-top: 0;
  text-align: center;
}

.upper-section {
  padding: 40px;
  max-width: 1500px;
  margin: 0px auto;
}

.form-info {
  display: grid;
  grid-template-columns: 50% 50%;
  margin: 30px auto;
}

.social-icons {
  grid-area: social;
  display: flex;
  justify-content: flex-start;
}

.icon {
  fill: white;
  margin-right: 15px;
}

.google-maps {
  width: 80%;
  margin-top: 15px;
  height: 400px;
}


</style>